<template>
  <div class="settings-container">
    <el-tabs v-model="activeTab" type="border-card">
      <!-- 基本设置 -->
      <el-tab-pane label="基本设置" name="basic">
        <el-form 
          ref="basicForm" 
          :model="basicForm" 
          :rules="basicRules"
          label-width="120px"
          :label-position="isMobile ? 'top' : 'right'"
        >
          <el-form-item label="网站名称" prop="siteName">
            <el-input v-model="basicForm.siteName" placeholder="请输入网站名称"></el-input>
          </el-form-item>
          
          <el-form-item label="客服QQ" prop="serviceQQ">
            <el-input v-model="basicForm.serviceQQ" placeholder="请输入客服QQ"></el-input>
          </el-form-item>
          
          <el-form-item label="客服微信" prop="serviceWechat">
            <el-input v-model="basicForm.serviceWechat" placeholder="请输入客服微信"></el-input>
          </el-form-item>
          
          <el-form-item label="网站公告" prop="announcement">
            <el-input 
              type="textarea" 
              v-model="basicForm.announcement" 
              :rows="4"
              placeholder="请输入网站公告"
            ></el-input>
          </el-form-item>

          <!-- 轮播图管理整合到这里 -->
          <el-form-item label="轮播图管理">
            <div class="banner-management">
              <!-- 上传按钮 -->
              <div class="banner-upload">
                <el-upload
                  class="upload-demo"
                  :action="`${baseURL}/api/admin/upload/banner`"
                  :headers="uploadHeaders"
                  :on-success="handleUploadSuccess"
                  :on-error="handleUploadError"
                  :before-upload="beforeBannerUpload"
                  :show-file-list="false"
                  accept=".jpg,.jpeg,.png"
                >
                  <el-button size="small" type="primary">上传轮播图</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
                </el-upload>
              </div>

              <!-- PC端表格 -->
              <el-table
                v-if="!isMobile"
                :data="bannerList"
                style="width: 100%; margin-top: 16px;"
              >
                <el-table-column label="预览图" width="180">
                  <template slot-scope="scope">
                    <img 
                      :src="baseURL + scope.row.imageUrl" 
                      style="width: 160px; height: 90px; object-fit: contain; border-radius: 4px; background: #f5f7fa;"
                    >
                  </template>
                </el-table-column>
                
                <el-table-column prop="title" label="标题">
                  <template slot-scope="scope">
                    <el-input 
                      v-model="scope.row.title" 
                      size="small"
                      placeholder="请输入标题"
                      @blur="updateBanner(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                
                <el-table-column prop="link" label="链接">
                  <template slot-scope="scope">
                    <el-input 
                      v-model="scope.row.link" 
                      size="small"
                      placeholder="请输入链接"
                      @blur="updateBanner(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                
                <el-table-column label="排序" width="120">
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.sort"
                      :min="0"
                      size="small"
                      style="width: 100px"
                      controls-position="right"
                      @change="(value) => updateBanner({...scope.row, sort: value})"
                    ></el-input-number>
                  </template>
                </el-table-column>
                
                <el-table-column label="状态" width="80">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      @change="(value) => updateBanner({...scope.row, status: value})"
                    ></el-switch>
                  </template>
                </el-table-column>
                
                <el-table-column label="操作" width="80" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="deleteBanner(scope.row.id)"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>

              <!-- 移动端卡片列表 -->
              <div v-else class="mobile-banner-list">
                <el-card v-for="item in bannerList" :key="item.id" class="banner-card">
                  <div class="banner-image">
                    <img 
                      :src="baseURL + item.imageUrl" 
                      style="width: 100%; height: 180px; object-fit: contain; border-radius: 4px; background: #f5f7fa;"
                    >
                  </div>
                  
                  <div class="banner-form">
                    <el-form label-position="top" size="small">
                      <el-form-item label="标题">
                        <el-input 
                          v-model="item.title" 
                          placeholder="请输入标题"
                          @blur="updateBanner(item)"
                        ></el-input>
                      </el-form-item>
                      
                      <el-form-item label="链接">
                        <el-input 
                          v-model="item.link" 
                          placeholder="请输入链接"
                          @blur="updateBanner(item)"
                        ></el-input>
                      </el-form-item>
                      
                      <el-form-item label="排序">
                        <el-input-number
                          v-model="item.sort"
                          :min="0"
                          style="width: 100%"
                          @change="(value) => updateBanner({...item, sort: value})"
                        ></el-input-number>
                      </el-form-item>
                    </el-form>
                  </div>
                  
                  <div class="banner-actions">
                    <el-switch
                      v-model="item.status"
                      @change="(value) => updateBanner({...item, status: value})"
                      style="margin-right: 12px"
                    ></el-switch>
                    <el-button 
                      type="danger" 
                      size="small"
                      @click="deleteBanner(item.id)"
                    >删除</el-button>
                  </div>
                </el-card>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <!-- 支付设置 -->
      <el-tab-pane label="支付设置" name="payment">
        <el-form 
          ref="paymentForm" 
          :model="paymentForm" 
          :rules="paymentRules"
          label-width="120px"
          :label-position="isMobile ? 'top' : 'right'"
        >
          <el-form-item label="最低充值" prop="minRecharge">
            <el-input-number 
              v-model="paymentForm.minRecharge" 
              :min="0"
              :precision="2"
              :step="10"
            ></el-input-number>
          </el-form-item>
          
          <el-form-item label="支付通道" prop="payChannels">
            <el-checkbox-group v-model="paymentForm.payChannels">
              <el-checkbox label="alipay">支付宝</el-checkbox>
              <el-checkbox label="wechat">微信支付</el-checkbox>
              <el-checkbox label="bank">银行卡</el-checkbox>
              <el-checkbox label="usdt">USDT</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <!-- 提现设置 -->
      <el-tab-pane label="提现设置" name="withdraw">
        <el-form 
          ref="withdrawForm" 
          :model="withdrawForm" 
          :rules="withdrawRules"
          label-width="120px"
          :label-position="isMobile ? 'top' : 'right'"
        >
          <el-form-item label="最低提现金额" prop="minWithdraw">
            <el-input-number 
              v-model="withdrawForm.minWithdraw" 
              :min="0"
              :precision="2"
              :step="10"
            ></el-input-number>
          </el-form-item>
          
          <el-form-item label="提现手续费率" prop="withdrawFee">
            <el-input-number 
              v-model="withdrawForm.withdrawFee" 
              :min="0"
              :max="100"
              :precision="2"
              :step="0.1"
            >
              <template slot="append">%</template>
            </el-input-number>
          </el-form-item>
          
          <el-form-item label="每日提现次数" prop="dailyWithdrawLimit">
            <el-input-number 
              v-model="withdrawForm.dailyWithdrawLimit" 
              :min="0"
              :precision="0"
              :step="1"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <!-- 代理设置 -->
      <el-tab-pane label="代理设置" name="agent">
        <el-form 
          ref="agentForm" 
          :model="agentForm" 
          :rules="agentRules"
          label-width="120px"
          :label-position="isMobile ? 'top' : 'right'"
        >
          <el-form-item label="一级代理佣金" prop="level1Commission">
            <el-input-number 
              v-model="agentForm.level1Commission" 
              :min="0"
              :max="100"
              :precision="2"
              :step="0.1"
            >
              <template slot="append">%</template>
            </el-input-number>
          </el-form-item>
          
          <el-form-item label="二级代理佣金" prop="level2Commission">
            <el-input-number 
              v-model="agentForm.level2Commission" 
              :min="0"
              :max="100"
              :precision="2"
              :step="0.1"
            >
              <template slot="append">%</template>
            </el-input-number>
          </el-form-item>
          
          <el-form-item label="三级代理佣金" prop="level3Commission">
            <el-input-number 
              v-model="agentForm.level3Commission" 
              :min="0"
              :max="100"
              :precision="2"
              :step="0.1"
            >
              <template slot="append">%</template>
            </el-input-number>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <!-- 保存按钮 -->
    <div class="settings-footer">
      <el-button 
        type="primary" 
        :loading="saving" 
        @click="saveSettings"
      >保存设置</el-button>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'Settings',
  data() {
    return {
      isMobile: false,
      activeTab: 'basic',
      saving: false,
      baseURL: config.baseURL,

      // 基本设置表单
      basicForm: {
        siteName: '',
        serviceQQ: '',
        serviceWechat: '',
        announcement: ''
      },
      basicRules: {
        siteName: [
          { required: true, message: '请输入网站名称', trigger: 'blur' }
        ]
      },

      // 支付设置表单
      paymentForm: {
        minRecharge: 100,
        payChannels: ['alipay', 'wechat']
      },
      paymentRules: {
        minRecharge: [
          { required: true, message: '请设置最低充值金额', trigger: 'blur' }
        ],
        payChannels: [
          { required: true, message: '请至少选择一个支付通道', trigger: 'change' }
        ]
      },

      // 提现设置表单
      withdrawForm: {
        minWithdraw: 100,
        withdrawFee: 1,
        dailyWithdrawLimit: 3
      },
      withdrawRules: {
        minWithdraw: [
          { required: true, message: '请设置最低提现金额', trigger: 'blur' }
        ],
        withdrawFee: [
          { required: true, message: '请设置提现手续费率', trigger: 'blur' }
        ],
        dailyWithdrawLimit: [
          { required: true, message: '请设置每日提现次数限制', trigger: 'blur' }
        ]
      },

      // 代理设置表单
      agentForm: {
        level1Commission: 5,
        level2Commission: 3,
        level3Commission: 1
      },
      agentRules: {
        level1Commission: [
          { required: true, message: '请设置一级代理佣金比例', trigger: 'blur' }
        ],
        level2Commission: [
          { required: true, message: '请设置二级代理佣金比例', trigger: 'blur' }
        ],
        level3Commission: [
          { required: true, message: '请设置三级代理佣金比例', trigger: 'blur' }
        ]
      },

      bannerList: [],
      uploadHeaders: {
        Authorization: `Bearer ${localStorage.getItem('admin_token')}`
      },
      uploadData: {
        type: 'home',
        position: 'top'
      }
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchSettings()
    this.fetchBannerList()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchSettings() {
      try {
        const res = await this.$http.get('/api/admin/settings')
        if (res.data.code === 200) {
          const { basic, payment, withdraw, agent } = res.data.data
          this.basicForm = basic
          this.paymentForm = payment
          this.withdrawForm = withdraw
          this.agentForm = agent
        }
      } catch (error) {
        this.$message.error('获取设置失败')
      }
    },
    async saveSettings() {
      try {
        // 验证所有表单
        await Promise.all([
          this.$refs.basicForm.validate(),
          this.$refs.paymentForm.validate(),
          this.$refs.withdrawForm.validate(),
          this.$refs.agentForm.validate()
        ])

        this.saving = true
        const settings = {
          basic: this.basicForm,
          payment: this.paymentForm,
          withdraw: this.withdrawForm,
          agent: this.agentForm
        }

        const res = await this.$http.post('/api/admin/settings', settings)
        if (res.data.code === 200) {
          this.$message.success('保存成功')
        }
      } catch (error) {
        console.log('Save settings failed')
      } finally {
        this.saving = false
      }
    },
    async fetchBannerList() {
      try {
        const res = await this.$http.get('/api/admin/banners')
        if (res.data.code === 200) {
          this.bannerList = res.data.data
        }
      } catch (error) {
        this.$message.error('获取轮播图列表失败')
      }
    },
    beforeBannerUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG 或 PNG 式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    handleUploadSuccess(res) {
      if (res.code === 200) {
        this.$message.success('上传成功')
        this.fetchBannerList()
      } else {
        this.$message.error(res.message || '上传失败')
      }
    },
    handleUploadError() {
      this.$message.error('上传失败')
    },
    async updateBanner(banner) {
      try {
        const res = await this.$http.put(`/api/admin/banners/${banner.id}`, banner)
        if (res.data.code === 200) {
          this.$message.success('更新成功')
        }
      } catch (error) {
        this.$message.error('更新失败')
        this.fetchBannerList() // 更新失败时重新获取列表
      }
    },
    async deleteBanner(id) {
      try {
        await this.$confirm('确定要删除这张轮播图吗？', '提示', {
          type: 'warning'
        })
        
        const res = await this.$http.delete(`/api/admin/banners/${id}`)
        if (res.data.code === 200) {
          this.$message.success('删除成功')
          this.fetchBannerList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除失败')
        }
      }
    }
  }
}
</script>

<style scoped>
.settings-container {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.settings-footer {
  margin-top: 20px;
  text-align: center;
}

/* 移动端适配优化 */
@media screen and (max-width: 768px) {
  .settings-container {
    padding: 0;  /* 移除容器内边距 */
  }

  /* Tab样式优化 */
  :deep(.el-tabs__header) {
    margin-bottom: 8px;  /* 减小tab和内容的间距 */
  }

  :deep(.el-tabs__item) {
    padding: 0 12px;  /* 减小tab项的内边距 */
    height: 36px;    /* 减小tab高度 */
    line-height: 36px;
  }

  /* 表单样式优化 */
  :deep(.el-form-item) {
    margin-bottom: 12px;  /* 减小表单项间距 */
  }

  :deep(.el-form-item__label) {
    padding-bottom: 4px;  /* 减小标签和输入框的间距 */
    line-height: 1.2;
    font-size: 14px;
  }

  :deep(.el-form-item__content) {
    line-height: 1;
  }

  :deep(.el-input__inner) {
    height: 36px;  /* 减小输入框高度 */
    line-height: 36px;
  }

  /* 轮播图管理样式优化 */
  .banner-management {
    margin-top: 4px;
  }

  .banner-upload {
    margin-bottom: 8px;
  }

  :deep(.el-upload__tip) {
    margin-top: 4px;
    font-size: 12px;
    color: #909399;
  }

  .mobile-banner-list {
    margin: 8px -12px 0;
  }

  .banner-card {
    margin-bottom: 8px;
    border-radius: 0;  /* 移除卡片圆角 */
  }

  :deep(.el-card__body) {
    padding: 12px;  /* 减小卡片内边距 */
  }

  .banner-image {
    margin: -12px -12px 8px;  /* 调整图片边距 */
  }

  .banner-image img {
    height: 150px;  /* 减小图片高度 */
  }

  .banner-form {
    margin-bottom: 8px;
  }

  .banner-form :deep(.el-form-item) {
    margin-bottom: 8px;
  }

  .banner-actions {
    padding-top: 8px;
    margin-top: 8px;
  }

  /* 按钮样式优化 */
  :deep(.el-button--small) {
    padding: 8px 12px;
    font-size: 13px;
  }

  /* 输入框和数字输入框统一样式 */
  :deep(.el-input-number) {
    width: 100%;
  }

  :deep(.el-textarea__inner) {
    min-height: 80px !important;  /* 减小文本域高度 */
  }

  /* 底部保存按钮优化 - 移除固定定位 */
  .settings-footer {
    margin: 16px 12px;  /* 修改边距，确保两边有间隔 */
    padding: 12px 0;    /* 只保留上下内边距 */
    background: transparent;  /* 移除背景色 */
    border-top: none;    /* 移除顶部边框 */
  }

  .settings-footer :deep(.el-button) {
    width: 100%;
    height: 40px;
  }
}

/* 超小屏幕额外优化 */
@media screen and (max-width: 375px) {
  :deep(.el-tabs__item) {
    padding: 0 8px;
    font-size: 13px;
  }

  .banner-image img {
    height: 120px;
  }

  :deep(.el-form-item__label),
  :deep(.el-input__inner) {
    font-size: 13px;
  }
}

.danger {
  color: #F56C6C;
}
</style> 